<template>
  <div class="project-form-container">
    <div class="project-form">

      <div class="project-form-title">
        Neuer Report
      </div>

      <div class="project-form-content">
        <form role="form">
          <div class="input-row">
            <label for="projectnumber">*Projektnummern</label>
            <input class="form-control"
                   id="projectnumber"
                   type="number"
                   :disabled="loading"
                   v-model="job.contract_numbers"
                   onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
          <div class="input-row" @click="showCalendar = !showCalendar">
            <label for="date">*Berichtszeitraum</label>
                <div class="container"
                  v-on="inputEvents">
                  <input
                  id="date"
                  class="form-control"
                  :value="date.month"
                >
                </div>
          </div>
             <month-picker lang="de" :no-default="true" v-if="showCalendar" @change="selectDate" class="calendar">
             </month-picker>

          <div class="input-row">
            <label for="name" class="label-name">*Name</label>
            <input class="form-control form-control-name"
                   id="name"
                   :disabled="loading"
                   v-model="job.name"
            />
          </div>

          <div class="login-error">
            <p v-if="error">{{errorMessage}}</p>
          </div>

          <div class="form-submit-buttons">
            <router-link to="/jobs"><img src="/images/cancel.png" alt="" /></router-link>
            <a v-if="job.name === '' || job.vertragnumber === '' || job.date === ''"
              style="cursor: default">
              <img src="/images/checkmark.png" alt="" />
            </a>
            <a v-else @click="saveJob"><img src="/images/ok.png" alt="" /> </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { MonthPicker } from 'vue-month-picker'
import moment from 'moment';

export default defineComponent({
  components: {
    MonthPicker
  },
  name: 'Job',
  data() {
    return {
      showCalendar: false,
      job: {
        contract_numbers: '',
        year: '',
        month: '',
        name: '',
      },
      date: {
				from: null,
				to: null,
				month: null,
				year: null
			},
      error: false,
      errorMessage: null,
      loading: false,
      showInput: false,
      projectId: '',
      masks: {
      input: 'MMMM',
      title: 'YYYY',
    },
    }
  },
  watch: {
    job: {
      handler(val){
        this.defaultName();
      },
      deep: true
    },
    date: function(val) {
      if(val) {
        this.defaultName();
      }
    }
  },
  methods: {
    selectDate(date) {
      this.date = date;
      if(this.date.month) {
        this.showCalendar = !this.showCalendar;
      }
    },
    defaultName() {
      if(this.job.contract_numbers && this.date.month) {
        const arrayNumbers = this.job.contract_numbers.split(',')
        this.job.name = `${arrayNumbers[0]}_${moment(this.date.from).format('YYMM')}_${moment().format('DDMMYY')}`
      }
    },

    saveJob() {
      if (!this.loading) {
        this.loading = true;
        this.job.year = moment(this.date.from).format('YYYY');
        this.job.month = moment(this.date.from).format('MM');
        this.$store.dispatch('saveJob', this.job).then(() => {
          this.loading = false;
          this.$router.push('/jobs')
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = error.response.data.error.message;
        })
      }
    },
  },
});
</script>

<style  lang="scss" scoped>

.container {
      position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
      width: 20px;
      height: 20px;
      background: url('/images/calendar.png') no-repeat;
      background-size: contain;
    }
    input {
      width: 100%;
    }
}

.calendar {
  margin: 0 auto;
  color: #022259;
}

.project-form-container {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .project-form {
    max-width: 450px;
    width: 100%;
    margin: 50px 0 100px;

    .project-form-title {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 42px;
      color: #022259;
    }

    .project-form-content {
      margin-top: 60px;

      .input-row {
        position: relative;
        margin: 40px 0;

        label {
          position: absolute;
          font-weight: 400;
          font-size: 18px;
          color: #022259;
          z-index: 5;
          background: #fff;
          left: 30px;
          top: -10px;
          padding: 0 3px;
        }

        .form-control {
          padding: 20px;
          height: 50px;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          color: #022259;
          border: 2px solid #022259;
          box-sizing: border-box;
          border-radius: 13px;
        }

        .form-control-name {
          font-weight: bold;
          font-style: normal;
        }

        .label-name {
          font-weight: bold;
          font-style: normal;
        }

        .name {
          font-weight: bold;
        }

        .password-view {
          position: absolute;
          top: 13px;
          right: 20px;
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url('/images/view.svg') 0 0 no-repeat;

          &.view {
            background: url('/images/no-view.svg') 0 0 no-repeat;
          }
        }
      }

      .login-error {
        padding: 20px 0;
        text-align: center;
        color: red;
      }

      .form-submit-buttons {
        text-align: center;

        a {
          padding: 0 70px;
          img {
            height: 80px;
          }
        }
      }
    }
  }
}
</style>
